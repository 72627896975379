<template>
  <b-modal
    v-model="conditionCancel"
    @hide="onHide"
    hide-footer
    hide-header
    centered
    size="md"
  >
    <validation-observer #default="{ handleSubmit }">
      <b-form
        class="auth-login-form mt-2"
        @submit.prevent="handleSubmit(onSubmitCancel)"
      >
        <!-- total -->
        <validation-provider
          v-if="dataAppointment"
          #default="{ errors }"
          name="Total"
          rules="required"
        >
          <b-form-group label="Total" label-for="total">
            <b-form-input
              id="total"
              name="total"
              disabled
              v-model="dataAppointment.total"
              :state="errors.length > 0 ? false : null"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
        </validation-provider>

        <!-- refund -->
        <validation-provider
          #default="{ errors }"
          name="Refund"
          rules="required"
        >
          <b-form-group label="Refund" label-for="refund">
            <b-form-input
              id="refund"
              name="refund"
              v-model="formRefund.refund"
              :state="errors.length > 0 ? false : null"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
        </validation-provider>

        <validation-provider
          #default="{ errors }"
          name="Payment Method"
          rules="required"
        >
          <b-form-group
            label="Payment Method"
            label-for="payment-method"
            :state="errors.length > 0 ? false : null"
            class="my-1"
          >
            <treeselect
            id="OMA"
            v-model="formRefund.type"
            :options="paymentMethodOptions"
            :normalizer="normalizer"
          />
            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
        </validation-provider>

        <!-- internal payment note-->
        <validation-provider
          #default="validationContext"
          name="Note"
          rules="required"
        >
          <b-form-group
            label="Note"
            label-for="internal-payment-note"
            class="my-1"
          >
            <b-form-textarea
              id="internal-payment-note"
              v-model="formRefund.note"
              placeholder="Internal payment note"
              no-resize
              rows="3"
              :class="{
                'is-invalid':
                  validationContext.touched && !validationContext.valid,
              }"
            />
            <small class="text-danger">{{ validationContext.errors[0] }}</small>
          </b-form-group>
        </validation-provider>

        <div class="d-flex justify-content-end mt-2">
          <b-button
            type="button"
            class="mr-2"
            variant="outline-danger"
            @click="onHide"
          >
            {{ $t("Cancel") }}
          </b-button>
          <b-button variant="warning" type="submit" :disabled="loading">
            <span v-if="!loading">Send</span>
            <span v-else>
              <SpinnerLoading />
            </span>
          </b-button>
        </div>
      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import {
  BModal,
  BForm,
  BFormGroup,
  BFormInput,
  BButton,
  BFormTextarea,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver, extend } from "vee-validate";

import flatPickr from "vue-flatpickr-component";

import SpinnerLoading from "@/components/SpinnerLoading";

import axiosOA from "@/core/services/api/appointment/actions";
import axiosA from "@/core/services/api/appointment";

import NotificationMixin from "@/mixins/NotificationMixin";

export default {
  components: {
    BModal,
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
    BFormTextarea,

    flatPickr,

    ValidationProvider,
    ValidationObserver,

    SpinnerLoading,
  },
  props: {
    appointmentById: {
      type: Number,
      required: true,
    },
  },
  mixins: [NotificationMixin],
  data() {
    return {
      conditionCancel: true,
      conditionSplit: true,
      loading: false,
      formRefund: {
        note: null,
        refund: null,
      },
      dataAppointment: null,
      paymentMethodOptions: [
        {
          name: "Cash",
          id: "cash",
        },
        {
          name: "Credit Card",
          id: "cc",
        },
      ],
      normalizer(node) {
        return {
          id: node.id,
          label: node.name,
        };
      },
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    onHide() {
      this.$emit("closeModalPay", false);
    },
    getData() {
      axiosA
        .appointmentDetail(this.appointmentById)
        .then(({ registro: { appointment } }) => {
          this.dataAppointment = appointment;
        });
    },
    async onSubmitCancel() {
      this.loading = true;
      const data = {
        ...this.formRefund,
      };
      await axiosOA
        .appointmentRefund(this.appointmentById, data)
        .then((res) => {
          const { type, message } =
            NotificationMixin.methods.notificationCheck(res);
          this.loading = false;
          this.$emit("paymentChecked", true);
          this.$emit("NotificationCheck", { type, message });
        })
        .catch((e) => {
          this.loading = false;
        });
      this.$emit("closeModalPay", false);
    },
  },
};
</script>