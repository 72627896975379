<template>
  <div>
    <!-- Media -->
    <b-media class="mb-2">
      <template #aside>
        <b-avatar
          ref="previewEl"
          :src="userData.avatar"
          :text="userData.name"
          :variant="`light-dark`"
          size="90px"
          rounded
        />
      </template>
      <h4 class="mb-1">{{ userData.name }} {{ userData.lastname }}</h4>
    </b-media>

    <!-- User Info: Input Fields -->
    <b-form>
      <b-row>
        <!-- Field: Username -->
        <b-col cols="12" md="4">
          <b-form-group :label="$t('Form.Name')" label-for="name">
            <b-form-input id="name" v-model="userData.name" />
          </b-form-group>
        </b-col>

        <!-- Field: Full Name -->
        <b-col cols="12" md="4">
          <b-form-group :label="$t('Form.Last Name')" label-for="last-name">
            <b-form-input id="last-name" v-model="userData.lastname" />
          </b-form-group>
        </b-col>

        <!-- Field: Email -->
        <b-col cols="12" md="4" v-if="!boolFrontDesk">
          <b-form-group label="Email" label-for="email">
            <b-form-input id="email" v-model="userData.email" type="email" />
          </b-form-group>
        </b-col>

        <b-col cols="12" md="4">
          <b-form-group :label="$t('Form.Phone')" label-for="phone">
            <b-form-input
              id="phone"
              class="form-control"
              v-model="formatPhone"
            />
          </b-form-group>
        </b-col>

        <b-col cols="12" md="4">
          <b-form-group label="Date Birth" label-for="date_birth">
            <flat-pickr
              id="creation"
              v-model="userData.date_birth"
              :config="config"
              class="form-control"
              placeholder="MM-DD-YYYY"
            />
          </b-form-group>
        </b-col>

        <b-col cols="12" md="4">
          <b-form-group :label="$t('Form.Gender')" label-for="gender">
            <treeselect
              id="gender"
              v-model="userData.gender"
              :options="genderOptions"
              :normalizer="normalizer"
            />
          </b-form-group>
        </b-col>

        <b-col cols="12" md="4">
            <b-form-group label="Reference" label-for="reference">
                <treeselect 
                  id="reference" 
                  v-model="userData.user_references_id"
                  :options="optionsReferenceList"
                  :normalizer="normalizer" />
            </b-form-group>
        </b-col>

        <b-col cols="12" md="4" v-if="!boolFrontDesk">
          <b-form-group label="Address" label-for="address">
            <b-form-input id="address" v-model="userData.address" />
          </b-form-group>
        </b-col>

        <b-col cols="12" md="4" v-if="!boolFrontDesk">
          <b-form-group :label="$t('Form.City')" label-for="city">
            <treeselect
              id="city"
              v-model="userData.cities_id"
              :defaultOptions="optionsCities"
              :load-options="searchCities"
              :async="true"
              :normalizer="normalizer"
            />
          </b-form-group>
        </b-col>

        <b-col cols="12" md="4" v-if="!boolFrontDesk">
          <b-form-group label="Zip Code" label-for="zipcode">
            <b-form-input id="zip_code" v-model="userData.zip_codes_id" />
          </b-form-group>
        </b-col>

        <!-- Field: Status -->
        <b-col cols="12" md="4" v-if="!boolFrontDesk">
          <b-form-group :label="$t('Form.Status')" label-for="user-status">
            <treeselect
              id="gender"
              v-model="userData.status"
              :options="statusOptions"
              :normalizer="normalizer"
            />
          </b-form-group>
        </b-col>

      </b-row>
    </b-form>
    <b-button
      variant="primary"
      class="mb-1 mb-sm-0 mr-0 mr-sm-1"
      @click="saveChanges"
      :disabled="loading"
    >
      <span v-if="!loading">Save Changes</span>
      <span v-else>
        <SpinnerLoading />
      </span>
    </b-button>

    <!-- Action Buttons -->

    <ToastNotification ref="toast" />
  </div>
</template>

<script>
import {
  BButton,
  BMedia,
  BAvatar,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BForm,
  BTable,
  BCard,
  BCardHeader,
  BCardTitle,
  BFormCheckbox,
  BFormFile,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email } from "@validations";
import flatPickr from "vue-flatpickr-component";

import axiosP from "@/core/services/api/patient";
import axiosC from "@/core/services/api/admin/cities";

import SpinnerLoading from "@/components/SpinnerLoading";
import ToastNotification from "@/components/ToastNotification";

import { onMounted, ref } from "@vue/composition-api";

export default {
  components: {
    BButton,
    BMedia,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BForm,
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
    BFormFile,
    flatPickr,
    SpinnerLoading,
    ToastNotification,
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password"
        ? "fas fa-eye"
        : "fas fa-eye-slash";
    },
    formatPhone: {
      get() {
        let { phone } = this.userData;
        if (phone) {
          phone = phone.toString().replace(/\D/g, "");
          const match = phone.match(/^(\d{1,3})(\d{0,3})(\d{0,4})$/);
          if (match) {
            phone = `(${match[1]}${match[2] ? "" : ""}) ${match[2]}${
              match[3] ? "-" : ""
            }${match[3]}`;
          }
          return phone;
        } else {
          return "";
        }
      },
      set(val) {
        this.userData.phone = val;
      },
    },
  },
  mounted() {
    if (JSON.parse(localStorage.getItem("userData")).roles[0].id == 5) {
        this.boolFrontDesk = true
    }
    this.selectedFacilities = this.userData.facilities;
  },
  data() {
    return {
      statusOptions: [
        { name: "Active", id: true },
        { name: "Inactive", id: false },
      ],
      genderOptions: [
        { name: "Male", id: "male" },
        { name: "Female", id: "female" },
      ],
      config: {
        dateFormat: "m-d-Y",
        allowInput: true,
      },
      fileContents: null,
      file: null,
      tags: "browser-upload",
      photoSelected: null,
      boolFrontDesk: false,
      loading: false,
      setTimeoutBuscador: "",
      optionsCities: [],
      normalizer(node) {
        return {
          id: node.id,
          label: node.name,
        };
      },
    };
  },
  setup(props, { emit }) {
    const optionsReferenceList = ref([]);

    const loadReferencesList = () => {
      axiosP.referencePagination(10, 1).then(({ data }) => {
        optionsReferenceList.value = data.data;
      });
    }

    onMounted(() => {
      loadReferencesList();
    });

    return {
      optionsReferenceList
    }
  },
  methods: {
    togglePasswordVisibility() {
      this.passwordFieldType =
        this.passwordFieldType === "password" ? "text" : "password";
    },
    saveChanges() {
      const phone = isNaN(this.userData.phone)
        ? this.userData.phone.replace(/[() -]/g, "")
        : this.userData.phone;
      axiosP
        .patientUpdate(this.userData.id, { ...this.userData, phone })
        .then(({ status }) => {
          if (status === "ERROR") {
            this.$refs.toast.danger("Error updating user");
          } else {
            this.$refs.toast.success("User updated successfully");
          }
        })
        .catch(() => {
          // eslint-disable-next-line no-alert
          this.$refs.toast.danger("Error updating user");
        });
    },

    searchCities(city, loading) {
      if (city.length) {
        loading(true);
        clearTimeout(this.setTimeoutBuscador);
        const datos = {
          name: city,
        };
        this.setTimeoutBuscador = setTimeout(() => {
          axiosC.citiesFilterFilters(datos).then(({ data }) => {
            loading(false);
            this.optionsCities = data;
          });
        }, 350);
      }
    }
  },
};
</script>
<style lang="scss" scoped>
.media-body {
  align-self: center;
}
</style>
