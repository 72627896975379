<template>
  <component :is="userData === undefined ? 'div' : 'b-card'">
    <!-- Alert: No item found -->
    <b-alert variant="danger" :show="userData === undefined">
      <h4 class="alert-heading">Error finding user PATIENT</h4>
      <div class="alert-body">
        No patient found with this patient id. Check
        <b-link class="alert-link" :to="{ name: 'patients' }">
          Patients List
        </b-link>
        for other patients.
      </div>
    </b-alert>
    <div class="d-flex flex-column align-items-end" style="height: 32px;">
      <GoBack class="mb-2" />
    </div>
    <b-tabs pills>
      <!-- Tab: Account -->
      <b-tab active v-if="userData">
        <template #title>
          <i class="fas fa-info mr-0 mr-sm-2"></i>
          <span class="d-none d-sm-inline">Information</span>
        </template>
        <PatientsEditInformation :user-data="userData" class="mt-2 pt-75" />
      </b-tab>

      <!-- Tab: Information -->
      <b-tab v-if="historyData">
        <template #title>
          <i class="fas fa-book-medical mr-0 mr-sm-2"></i>
          <span class="d-none d-sm-inline">Clinical Picture</span>
        </template>
        <PatientsClinicalPicture
          class="mt-2 pt-75"
          :history-data="historyData"
        />
      </b-tab>

      <!-- Tab: Social -->
      <b-tab v-if="appointmentData">
        <template #title>
          <i class="fas fa-calendar-plus mr-0 mr-sm-2"></i>
          <span class="d-none d-sm-inline">Appointment</span>
        </template>
        <PatientsAppointments
          class="mt-2 pt-75"
          :appointment-data="appointmentDataId"
          @patientAppointment="getAppointmentPatient"
        />
      </b-tab>
      
      <b-tab v-if="membershipData">
        <template #title>
          <i class="fas fa-calendar-plus mr-0 mr-sm-2"></i>
          <span class="d-none d-sm-inline">Membership</span>
        </template>
        <PatientsMembership
          class="mt-2 pt-75"
          :membership-data="membershipData"
          @patientMembership="getMembershipPatient"
        />
      </b-tab>
    </b-tabs>
  </component>
</template>

<script>
import { BTab, BTabs, BCard, BAlert, BLink } from "bootstrap-vue";
import { ref, onMounted } from "@vue/composition-api";
import router from "@/router";
import PatientsEditInformation from "./PatientsEditInformation.vue";
import PatientsClinicalPicture from "./PatientsClinicalPicture.vue";
import PatientsAppointments from "./PatientsAppointments.vue";
import PatientsMembership from "./PatientsMembership.vue";

import GoBack from "@/components/ButtonBack.vue";

import axiosP from "@/core/services/api/patient";
import axiosPH from "@/core/services/api/patient/history";
import axiosPA from "@/core/services/api/patient/appointment";
import axiosM from "@/core/services/api/admin/consults/memberships";

export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,

    GoBack,

    PatientsEditInformation,
    PatientsClinicalPicture,
    PatientsAppointments,
    PatientsMembership
  },
  setup() {
    const userData = ref(null);
    const historyData = ref(null);
    const appointmentData = ref(null);
    const appointmentDataId = ref(null);
    const membershipData = ref(null);


  

    onMounted(() => {
      getAppointmentPatient();
      getMembershipPatient();
    }),
      // Get user data
      axiosP
        .patientDetail(router.currentRoute.params.id)
        .then(({ registro }) => {
          userData.value = registro;
        });

    axiosPH.historyList(router.currentRoute.params.id).then(({ registro }) => {
      historyData.value = registro;
    });

    const getAppointmentPatient = () => {
      axiosPA
        .appointmentList(router.currentRoute.params.id)
        .then(({ registro }) => {
          appointmentData.value = registro.data;
          appointmentDataId.value = router.currentRoute.params.id
        });
    };
    
    const getMembershipPatient = () => {
      axiosM.membershipsPerPatient(router.currentRoute.params.id).then(({ data }) => {
          membershipData.value = data;
        });
    };

    return {
      userData,
      historyData,
      appointmentData,
      membershipData,
      appointmentDataId,
      getAppointmentPatient,
      getMembershipPatient
    };
  },
};
</script>
