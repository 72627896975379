export default {
    data: () => ({
    }),
    methods: {
        //Funcion para verificar si el usuario tiene permiso para realizar la accion
        notificationCheck(objectBackend) {
            const { status, type, message} = objectBackend

            let typeMessage

            typeof type === 'string' ? typeMessage = type.toLowerCase() : typeMessage = type

            let notificacionOptions = {}
            if(status === 'ERROR'){
                if(typeMessage === 'validation' || type == null){
                    notificacionOptions = {
                        type: 'warning',
                        message
                    }
                }else{
                    notificacionOptions = {
                        type: 'danger',
                        message
                    }
                }
            }else{
                notificacionOptions = {
                    type: 'success',
                    message
                }
            }
            return notificacionOptions
        }
    },
};