<template>
  <b-card>
    <b-card-title class="d-flex justify-content-between">
      <div>
        <p class="">
          number of visits:
          <span class="text-primary">
            {{ historyData.visit_number }}
          </span>
        </p>
      </div>
      <small class="text-muted">updated on: {{ historyData.updated_at }}</small>
    </b-card-title>
    <b-row>
      <!-- image top -->
      <b-col md="6" xl="4">
        <b-card
          title="Ailment"
          class="mb-3 text-center"
          border-variant="primary"
        >
          <b-card-text>
            <b-list-group flush>
              <b-list-group-item
                v-for="data in historyData.ailment"
                :key="data.id"
              >
                {{ data.name }}
              </b-list-group-item>
            </b-list-group>
          </b-card-text>
          
        </b-card>
      </b-col>
      <b-col md="6" xl="4">
        <b-card
          title="Allergies"
          class="mb-3 text-center"
          border-variant="primary"
        >
          <b-card-text>
            <b-list-group flush>
              <b-list-group-item
                v-for="data in historyData.allergies"
                :key="data.id"
              >
                {{ data.name }}
              </b-list-group-item>
            </b-list-group>
          </b-card-text>
          
        </b-card>
      </b-col>
      <b-col md="6" xl="4">
        <b-card
          title="Medications"
          class="mb-3 text-center"
          border-variant="primary"
        >
          <b-card-text>
            <b-list-group flush>
              <b-list-group-item
                v-for="data in historyData.medication_informations"
                :key="data.id"
              >
                {{ data.medications.name }}
              </b-list-group-item>
            </b-list-group>
          </b-card-text>
          
        </b-card>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardTitle,
  BCardText,
  BListGroup,
  BListGroupItem,
} from "bootstrap-vue";
export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardTitle,
    BCardText,
    BListGroup,
    BListGroupItem,
  },
  props: {
    historyData: {
      type: Object,
      default: () => {},
      required: true,
    },
  },
};
</script>

<style>
</style>