import { userAxios } from '../index'

const appointmentDelete = async (id) => {
    try {
        return await userAxios.post(`consultation/delete`, id).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const appointmentChangeFacility = async (data) => {
    try {
        return await userAxios.post(`consultation/changeClinic`, data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const appointmentChangeOMA = async (data) => {
    try {
        return await userAxios.post('consultation/changeOma', data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const appointmentAssignCuppon = async (data) => {
    try {
        return await userAxios.post(`validate_cupon`, data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const appointmentCancel = async (appoinment, data) => {
    try {
        return await userAxios.put(`officeVisits/cancel/${appoinment}`).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const appointmentRefund = async (appoinment, data) => {
    try {
        return await userAxios.post(`consultation/refund/${appoinment}`, data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}



export default {
    appointmentDelete,
    appointmentChangeFacility,
    appointmentChangeOMA,
    appointmentAssignCuppon,
    appointmentCancel,
    appointmentRefund
}
