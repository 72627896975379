import { userAxios } from "../index";

/**
 * It returns a promise that resolves to the data property of the response object returned by the
 * userAxios.get() method.
 * @param data - {
 * @returns The data from the API call.
 */
const userList = async (perPage) => {
    try {
        return await userAxios.get(`users/all/${perPage}`).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const userPagination = async (perPage, page) => {
    try {
        return await userAxios.get(`users/all/${perPage}?page=${page}`).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

/**
 * It takes in a data object, and then sends a post request to the server with the data object.
 * @param data - {
 */
const userCreate = async (data) => {
    try {
        return await userAxios.post('users', data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

/**
 * It's an async function that makes a GET request to the userAxios instance, and returns the data from
 * the response.
 * @param id - the id of the user you want to get the details of
 */
const userDetail = async (id) => {
    try {
        return await userAxios.get(`users/${id}`).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

/**
 * It takes in an id and data, and then it makes a put request to the server with the id and data.
 * @param id - the id of the user you want to update
 * @param data - {
 */
const userUpdate = async (id, data) => {
    try {
        return await userAxios.put(`users/${id}`, data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

/**
 * This function will delete a user from the database, and return the deleted user's data.
 * @param id - the id of the user you want to delete
 */
const userDelete = async (id) => {
    try {
        return await userAxios.delete(`users/${id}`).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const userUpdateFacilities = async (id, data) => {
    try {
        return await userAxios.put(`users/clinic/${id}`, data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const userUpdateState = async (id, data) => {
    try {
        return await userAxios.put(`users/activateAccount/${id}`, data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const userFilter = async (perPage, data) => {
    try {
        return await userAxios.post(`users/search/${perPage}`, data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const UserHasOma = async (idUser, dataOma) => {
    try {
        return await userAxios.put(`users_has_oma/${idUser}`, dataOma).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const ShowOmaUser = async (id) => {
    try {
        return await userAxios.get(`users_has_oma/show/${id}`).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

export default {
    userList,
    userPagination,
    userCreate,
    userUpdate,
    userDelete,
    userDetail,
    userUpdateFacilities,
    userUpdateState,
    userFilter,
    UserHasOma,
    ShowOmaUser
}