<template>
    <div>
        <b-modal id="add-new-patient-sidebar" :visible="verifyBool" bg-variant="white" shadow backdrop no-header
            @change="(val) => changeStateSidebar(val)" size="xl">
            <template #default="{ hide }">
                <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
                    <h5 class="mb-0">
                        Details appointment
                    </h5>

                    <i class="fas fa-times ml-1 cursor-pointer" size="16" @click="hide"></i>
                </div>

                <div class="p-2 card-container">
                    <div v-for="(appointment, index) in info" :key="index" class="campaign-info">
                        <h4 class="info-title">Membership: {{ appointment.campaign }}</h4>
                        <div class="info-details">
                            <p>facility: {{ appointment.facility }}</p>
                            <p>visit date: {{ new Date(appointment.visit_date).toLocaleString() }}</p>
                            <p>Total: {{ appointment.total }}</p>
                            <p>Discount: {{ appointment.discount }}</p>
                            <p>Diagnostic Imagings:</p>
                            <ul class="list-unstyled my-2 facility">
                                <li v-for="(diagnostic, i) in appointment.diagnostic_imagings" :key="i">
                                    <b-badge class="badge-wrapper" variant="primary">
                                        <span class="text-white">
                                            {{ diagnostic.name }}
                                        </span>
                                    </b-badge>
                                </li>
                            </ul>
                            <p>laboratories:</p>
                            <ul class="list-unstyled my-2 facility">
                                <li v-for="(laboratories, i) in appointment.laboratories" :key="i">
                                    <b-badge class="badge-wrapper" variant="primary">
                                        <span class="text-white">
                                            {{ laboratories.name }}
                                        </span>
                                    </b-badge>
                                </li>
                            </ul>
                            <p>laboratories_pack:</p>
                            <ul class="list-unstyled my-2 facility">
                                <li v-for="(laboratories_pack, i) in appointment.laboratories_pack" :key="i">
                                    <b-badge class="badge-wrapper" variant="primary">
                                        <span class="text-white">
                                            {{ laboratories_pack.name }}
                                        </span>
                                    </b-badge>
                                </li>
                            </ul>
                            <p>procedures:</p>
                            <ul class="list-unstyled my-2 facility">
                                <li v-for="(procedures, i) in appointment.procedures" :key="i">
                                    <b-badge class="badge-wrapper" variant="primary">
                                        <span class="text-white">
                                            {{ procedures.name }}
                                        </span>
                                    </b-badge>
                                </li>
                            </ul>
                            <p>tests:</p>
                            <ul class="list-unstyled my-2 facility">
                                <li v-for="(tests, i) in appointment.tests" :key="i">
                                    <b-badge class="badge-wrapper" variant="primary">
                                        <span class="text-white">
                                            {{ tests.name }}
                                        </span>
                                    </b-badge>
                                </li>
                            </ul>
                            <p>treatments:</p>
                            <ul class="list-unstyled my-2 facility">
                                <li v-for="(treatments, i) in appointment.treatments" :key="i">
                                    <b-badge class="badge-wrapper" variant="primary">
                                        <span class="text-white">
                                            {{ treatments.name }}
                                        </span>
                                    </b-badge>
                                </li>
                            </ul>
                        </div>
                        <!-- <b-button variant="primary" @click="seeMembership(appointment.id)">See membership</b-button> -->
                    </div>
                </div>
            </template>
        </b-modal>
        <ToastNotification ref="toast" />
    </div>
</template>

<script>
import { BModal, BBadge } from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";

import SpinnerLoading from "@/components/SpinnerLoading";

import { ref, watch } from "@vue/composition-api";

import ToastNotification from "@/components/ToastNotification";

/* import ConsultAdd from "@/views/admin/type-consult/consult/ConsultAdd"; */
export default {
    components: {
        BModal,
        // SpinnerLoading,
        // ValidationProvider,
        ToastNotification,
        // ValidationObserver,
        BBadge
    },
    model: {
        prop: "verifyBool",
        event: "update:verifyBool",
    },
    props: {
        verifyBool: {
            type: Boolean,
            required: true,
        },
        appointmentsData: {
            type: Object,
            default: () => { },
            required: false,
        }
    },
    setup(props, { emit }) {
        const toast = ref(null)

        const info = ref([])

        const seeMembership = (id) => {
            console.log(id)
        }

        watch(() => props.verifyBool, () => {
            info.value = []
            if (props.verifyBool == true) appointmentVerified(props.appointmentsData)
        })

        const appointmentVerified = (appointmentsData) => {
            function organizeData(property) {
                const newArray = []
                appointmentsData.appointments.forEach(appointment => {
                    appointment[property].forEach(({ name }) => {
                        newArray.push({ name })
                    })
                })
                return newArray
            }
            let visit_date = "",
                facility = ""
            const diagnostic_imagings = organizeData("diagnostic_imagings"),
                laboratories = organizeData("laboratories"),
                laboratories_pack = organizeData("laboratories_pack"),
                procedures = organizeData("procedures"),
                tests = organizeData("tests"),
                treatments = organizeData("treatments")

                let total = 0, discount = 0 
            appointmentsData.appointments.forEach(appointment => {
                facility = appointment.appointments.facilities.name
                total = appointment.appointments.total
                discount = appointment.appointments.ajustment
                visit_date = appointment.created_at
            })

            info.value.push({
                campaign: appointmentsData.memberships_parameters.name,
                id: appointmentsData.detail_used.memberships_parameters_id,
                total,
                discount,
                facility,
                visit_date,
                diagnostic_imagings,
                laboratories,
                laboratories_pack,
                procedures,
                tests,
                treatments,
            })
        }

        const changeStateSidebar = (val) => {
            emit('update:verifyBool', val)
        }

        return {
            changeStateSidebar,
            info,
            seeMembership
        };
    },
};
</script>

<style lang="scss" scoped>
.card-container {
    display: flex;
}

.campaign-info {
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    padding: 20px;
    border-radius: 8px;
    max-width: 400px;
    width: 100%;
    margin: 1%;
}

.info-title {
    color: #3498db;
    font-size: 1.5em;
    margin-bottom: 10px;
}

.info-details p {
    margin: 5px 0;
}

.badge-wrapper {
    overflow: auto;
    white-space: pre-wrap;
}
</style>
